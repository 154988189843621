.register__container {
    display: flex;
    flex-direction: row;
    gap: 10px;
    background-color: #fff;
    padding: 15px;
    flex-wrap: wrap;
}

.register__table {
    width: 130px;
    height: 130px;
    background-color: #303952;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 30px;
    padding: 5px;
    cursor: pointer;
    color: #fff;
}

.register__table__money {
    font-size: 1.2rem;
    font-weight: bold;
}

.register__total {
    font-size: 1.4rem;
}

.background-blue {
    background-color: #4e73df !important;
    color: #fff;
}

.border-card {
    border: 1px solid #4e73df !important;
}