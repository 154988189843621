.order__categories {
  background-color: #4e73df;
  color: #fff;
  font-size: 1.2rem;
}

.product__container {
  gap: 10px;
  flex-wrap: wrap;
}

.product {
  width: 100px;
  height: 130px;
  background-color: #303952;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 5px;
  cursor: pointer;
}

.product__image {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 50%;
}

.product__name {
  color: #fff;
  text-align: center;
}

.modal__category {
  color: #fff;
}

.modal__products {
  color: #212529;
}

.modal__actions {
  display: flex;
  gap: 5px;
}

.modal__input {
  width: 30px;
  height: 30px;
  margin: 0;
  padding: 0;
  text-align: center;
  -moz-appearance: textfield;
}

.modal__input::-webkit-outer-spin-button,
.modal__input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}

.modal__aditions {
  display: flex;
  flex-direction: column;
}

.styled-checkbox {
  position: absolute;
  opacity: 0;
}

.styled-checkbox+label {
  position: relative;
  cursor: pointer;
  padding: 0;
}

.styled-checkbox+label:before {
  content: '';
  margin-right: 10px;
  display: inline-block;
  vertical-align: text-top;
  width: 20px;
  height: 20px;
  background: #ced6e0;
}

.styled-checkbox:hover+label:before {
  background: #f35429;
}

.styled-checkbox:focus+label:before {
  box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
}

.styled-checkbox:checked+label:before {
  background: #f35429;
}

.styled-checkbox:disabled+label {
  color: #b8b8b8;
  cursor: auto;
}

.styled-checkbox:disabled+label:before {
  box-shadow: none;
  background: #ddd;
}

.styled-checkbox:checked+label:after {
  content: '';
  position: absolute;
  left: 5px;
  top: 9px;
  background: white;
  width: 2px;
  height: 2px;
  box-shadow: 2px 0 0 white, 4px 0 0 white, 4px -2px 0 white, 4px -4px 0 white, 4px -6px 0 white, 4px -8px 0 white;
  transform: rotate(45deg);
}

.background-blue {
  background-color: #4e73df !important;
  color: #fff;
}

.border-card {
  border: 1px solid #4e73df !important;
}